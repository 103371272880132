import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as Dialog from '@radix-ui/react-dialog';
import Toolbar from './Toolbar';
import { ReactComponent as Close } from 'src/iconic/close.svg';

const sizes = {
  sm: { height: 220, width: 360 },
  md: { height: 700, width: 660 },
  lg: { height: 900, width: 768 },
};

type ModalSize = keyof typeof sizes;

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: ModalSize;
  topItems?: React.ReactNode;
  bottomItems?: React.ReactNode;
  title?: string;
  gray?: boolean;
  mobileOnly?: boolean;
  showToolbar?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  size = 'lg',
  topItems,
  bottomItems,
  title,
  padded = true,
  gray = false,
  mobileOnly = false,
  showToolbar = true,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      {open && (
        <Helmet>
          <meta
            name="theme-color"
            content="#777777"
            media={mobileOnly ? 'max-width: 1450px' : undefined}
          />
          <meta name="theme-color" content="#000000" media="(prefers-color-scheme: dark)" />
          {/* Override global styles injected by Radix Dialog component that break the layout */}
          <style type="text/css">
            {`
              html body[data-scroll-locked] {
                position: static !important;
              }
            `}
          </style>
        </Helmet>
      )}
      <Dialog.Portal>
        <StyledOverlay />
        <StyledContent size={size} gray={gray}>
          {showToolbar && (
            <Toolbar modal={true} top={true} half={false} bg="transparent" border={true}>
              <StyledModalTitle>{title}</StyledModalTitle>
              <StyledModalClose onClick={onClose}>
                <Close stroke="var(--c-fg-0)" />
              </StyledModalClose>
              {topItems}
            </Toolbar>
          )}
          <StyledModalBody padded={padded} showToolbar={showToolbar} showBottomBar={bottomItems}>
            {children}
          </StyledModalBody>
          {bottomItems && (
            <Toolbar modal={true} top={true} half={false} bg="transparent" border={true}>
              {bottomItems}
            </Toolbar>
          )}
        </StyledContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

interface StyledModalBodyProps {
  showToolbar: boolean;
  showBottomBar: boolean;
}

interface StyledContentProps {
  size: ModalSize;
  gray: boolean;
}

const StyledOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.533);
  box-sizing: border-box;
  pointer-events: all;
  z-index: var(--z-overlay);
`;

const StyledContent = styled(Dialog.Content)<StyledContentProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-modal);
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.gray ? 'var(--c-bg-gray)' : 'var(--bgc)')};
  color: var(--fg);
  max-height: min(${(props) => sizes[props.size].height}px, calc(100vh - 32px));
  max-width: ${(props) => sizes[props.size].width}px;
  width: 100%;
  height: auto;
  border-radius: 24px;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 0px 100px;
  will-change: transform, opacity;

  @media (max-width: 768px) {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    padding: 0;
    border-radius: 39px 39px 0 0;
  }
`;

const StyledModalBody = styled.div<StyledModalBodyProps>`
  flex: 1;
  padding: ${(props) =>
    props.padded
      ? `${props.showToolbar ? 'var(--s-nav-height)' : '0'} var(--side-margin) 42px`
      : '0px'};

  overflow: auto;
  background: transparent;
  margin-bottom: ${(props) => (props.showBottomBar ? '64px' : '0')};
  ${({ showToolbar }) => !showToolbar && 'padding-top: 0;'}
  ${({ showBottomBar }) => showBottomBar && 'padding-bottom: 64px;'}
`;

const StyledModalTitle = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  z-index: -1;
  pointer-events: none;
  font-size: var(--fs-b2);
  font-weight: 500;
`;

const StyledModalClose = styled.button`
  position: absolute;
  right: 32px;
  height: 32px;
  background: var(--c-bg-c);
  border: 0;
  border-radius: 50%;
  padding: 0;
  height: 32px;
  display: flex;
  width: 32px;
  align-items: center;
  justify-content: center;
  margin-left: -6px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

export default Modal;
