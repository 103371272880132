import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface TextPillProps {
  small?: boolean;
  asset?: ReactNode;
  children: ReactNode;
}

const STextPill = styled.div`
  fontSize: var(--fs-fp),
  font-family: var(--ff-base);
  background: var(--c-bg-gray-light);
  border-radius: 1000px;
  color: var(--c-fg-0);


  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 2px solid var(--c-system);
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  padding: ${(p) => (p.$small ? '8px 16px 8px 12px' : '24px')};
  .asset {
    padding-right: 12px;
    padding-right: ${(p) => (p.$small ? '12px' : '16px')};
  }

  ${(p) =>
    !p.$small &&
    `
    @media (max-width: 568px) {
      border-radius: 50px;
    }
  `}
  
`;

const TextPill: React.FC<TextPillProps> = ({ small, asset, children }) => {
  return (
    <STextPill $small={!!small}>
      {asset && <div className="asset">{asset}</div>}
      <span>{children}</span>
    </STextPill>
  );
};

export default TextPill;
