import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Accordion, Callout, Resource } from 'src/components';

import { ReactComponent as Left } from 'src/iconic/arrow-left.svg';
import { ReactComponent as Right } from 'src/iconic/arrow-right.svg';
import { Markdown } from 'src/kit';
import paths from 'src/constants/paths';
import SessionContext from 'src/context/SessionContext';

const SPost = styled.div`
  box-sizing: border-box;
  .body {
    max-width: 100%;
    margin: 0 auto;
    h2 {
      margin: 0;
      font-size: calc(var(--fs-h2) / 2 + var(--fs-h3) / 2);
      line-height: 1.15;
    }
  }
`;

/**
 *
 */
const Snippet = ({ post, guide, prevPost, nextPost, chapterTitle }) => {
  const { isAetnaSessionActive } = useContext(SessionContext);
  return (
    <SPost narrow small>
      <div className="body">
        <b2 style={{ marginTop: -12, color: `var(--c-${guide?.color}-0)` }}>{chapterTitle}</b2>
        <h2 style={{ margin: 0, marginTop: 12 }}>{post?.title}</h2>
        {post?.subtitle && (
          <b1 style={{ fontWeight: 400, opacity: 0.5, marginTop: 12 }}>{post?.subtitle}</b1>
        )}

        <article className="article" style={{ marginTop: 32 }}>
          {post?.functionality?.title && (
            <div className="dark">
              <Callout
                imageSrc="/symbol-dark.png"
                title={post.functionality?.title}
                text={post.functionality?.text}
                buttonText={post.functionality?.button}
                buttonUrl={post.functionality?.url}
                buttonAction={() => {
                  if (isAetnaSessionActive) {
                    console.log('Aetna session ongoing');
                  } else {
                    console.log('Aetna session not active');
                  }
                }}
              />
            </div>
          )}
          {post?.tldr && (
            <Callout imageSrc="/iconic/text-align-left.svg" eb="tl;dr" title={post.tldr} />
          )}

          <Markdown>{post?.body}</Markdown>
        </article>

        {post?.faqs?.length > 0 && (
          <div style={{ marginTop: 72 }}>
            <h4>FAQ</h4>
            <Accordion
              items={post?.faqs?.map((faq) => ({
                title: faq?.title,
                content: faq?.explanation,
              }))}
            />
          </div>
        )}

        <div className="flex no-flex-mob" style={{ marginTop: 72 }}>
          <div style={{ flex: 1 }}>
            {prevPost && (
              <Link to={`${paths.GUIDES}/${guide?.slug}/${prevPost?.slug}`}>
                <Resource
                  icon={Left}
                  text="Previous post"
                  title={prevPost?.shortTitle || prevPost?.title}
                />
              </Link>
            )}
          </div>
          <div style={{ flex: 1 }}>
            {nextPost && (
              <Link to={`${paths.GUIDES}/${guide?.slug}/${nextPost?.slug}`}>
                <Resource
                  icon={Right}
                  text="Next post"
                  title={nextPost?.shortTitle || nextPost?.title}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </SPost>
  );
};

export default Snippet;
